import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  primetirages: {
    count: 0,
    data: [],
    num_pages: 1,
    page: 1,
    page_size: "5",
  },
};

const slice = createSlice({
  name: "primetirage",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRIME TIRAGE
    getPrimeTirageSuccess(state, action) {
      state.isLoading = false;
      state.primetirages = action.payload;
    },

    // ADD PRIME TIRAGE
    addPrimeTirageSuccess(state, action) {
      const primetirage = action.payload;
      state.primetirages.data.push(primetirage);
      state.primetirages.count += 1;
    },

    // UPDATE PRIME TIRAGES
    updatePrimeTirageSuccess(state, action) {
      const primetirage = action.payload;
      if (state.primetirages && state.primetirages.data) {
        const primetirageIndex = state.primetirages?.data?.findIndex(
          (obj) => obj.id === primetirage.id
        );
        if (primetirageIndex !== -1) {
          state.isLoading = false;
          state.primetirages.data[primetirageIndex] = primetirage;
        }
      } else {
        state.primetirages = { data: [] };
        state.primetirages.data.push(primetirage);
      }
    },

    // DELETE PRIME TIRAGE
    deletePrimeTirageSuccess(state, action) {
      const deletedPrimeTirageId = action.payload;
      state.primetirages.data = state.primetirages.data.filter(
        (primetirage) => primetirage.id !== deletedPrimeTirageId
      );
      state.isLoading = false;
    },

    // DELETE PRIME TIRAGES
    deletePrimeTiragesSuccess(state, action) {
      const deletedPrimeTirageIds = action.payload;
      state.primetirages.data = state.primetirages.data.filter(
        (primetirage) => !deletedPrimeTirageIds.includes(primetirage.id)
      );
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addPrimeTirageSuccess,
  updatePrimeTirageSuccess,
  deletePrimeTirageSuccess,
  deletePrimeTiragesSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getPrimeTirages(page = 1, rowsPerPage = 5) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/dashboard/prime-tirage?page=${page}&page_size=${rowsPerPage}`
      );
      dispatch(slice.actions.getPrimeTirageSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getPrimeTirage(keyId) {
  const response = await axios.get(
    `/api/dashboard/prime-tirage-id?keyId=${keyId}`
  );
  return response.data;
}

export async function addPrimeTirage(data) {
  const response = await axios.post(`/api/dashboard/prime-tirage`, data);
  return response.data;
}

export async function updatePrimeTirage(data) {
  const response = await axios.put(`/api/dashboard/prime-tirage`, data);
  return response.data;
}

export async function deletePrimeTirage(keyId) {
  const response = await axios.delete(`/api/dashboard/prime-tirage/${keyId}/`);
  return response.data;
}

export async function deletePrimeTirages(keyIds) {
  const response = await axios.delete(`/api/dashboard/prime-tirage-id`, {
    data: { keyIds },
  });
  return response.data;
}
