import { createSlice } from "@reduxjs/toolkit";
// utils
import axios from "../../utils/axios";
//
import { dispatch } from "../store";

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  vendors: { count: 0, data: [], num_pages: 1, page: 1, page_size: "5" },
};

const slice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET VENDORS
    getVendorSuccess(state, action) {
      state.isLoading = false;
      state.vendors = action.payload;
    },

    // ADD VENDOR
    addVendorSuccess(state, action) {
      const vendor = action.payload;
      state.vendors.data.push(vendor);
      state.vendors.count += 1;
    },

    // UPDATE VENDOR
    updateVendorSuccess(state, action) {
      const vendor = action.payload;
      if (state.vendors && state.vendors.data) {
        const vendorIndex = state.vendors?.data?.findIndex(
          (obj) => obj.id === vendor.id
        );
        if (vendorIndex !== -1) {
          state.isLoading = false;
          state.vendors.data[vendorIndex] = vendor;
        }
      } else {
        state.vendors = { data: [] };
        state.vendors.data.push(vendor);
      }
    },

    // DELETE VENDOR
    deleteVendorSuccess(state, action) {
      const deletedVendorId = action.payload;
      state.vendors.data = state.vendors.data.filter(
        (vendor) => vendor.id !== deletedVendorId
      );
      state.isLoading = false;
    },

    // DELETE VENDORS
    deleteVendorsSuccess(state, action) {
      const deletedVendorIds = action.payload;
      state.vendors.data = state.vendors.data.filter(
        (vendor) => !deletedVendorIds.includes(vendor.id)
      );
      state.isLoading = false;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addVendorSuccess,
  updateVendorSuccess,
  deleteVendorSuccess,
  deleteVendorsSuccess,
} = slice.actions;

// ----------------------------------------------------------------------

export function getVendors(page = 1, rowsPerPage = 5) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `/api/dashboard/vendors?page=${page}&page_size=${rowsPerPage}`
      );
      dispatch(slice.actions.getVendorSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getVendor(keyId) {
  const response = await axios.get(`/api/dashboard/vendor-id?keyId=${keyId}`);
  return response.data;
}

export async function addVendor(data) {
  const response = await axios.post(`/api/dashboard/vendors`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}

export async function generateVendor(data) {
  const response = await axios.post(`/api/dashboard/generate-vendors`, data);
  return response.data;
}

export async function updateVendor(data) {
  const response = await axios.put(`/api/dashboard/vendors`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
}

export async function deleteVendor(keyId) {
  const response = await axios.delete(`/api/dashboard/vendors/${keyId}/`);
  return response.data;
}

export async function deleteVendors(keyIds) {
  const response = await axios.delete(`/api/dashboard/vendor-id`, {
    data: { keyIds },
  });
  return response.data;
}
